<template>
    <div class="add-banner">
        <el-form ref="form" :model="form" >
            <el-form-item label="banner跳转地址">
                    <el-input v-model="form.url" :placeholder="this.CONST.INPUT_HINT_NAME">
                        <el-select v-model="selectType" slot="append" placeholder="类型选择"  @change="selectToType">
                            <el-option label="跳转测试题" value="1"></el-option>
                            <el-option label="跳转课程" value="2"></el-option>
                        </el-select>
                    </el-input>
            </el-form-item>
            <el-form-item label="Banner图片" required>
               <!-- upload -->
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-error="uploadFail"
                    :before-upload="change"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                    <img v-if="form.image" :src="form.image" class="avatar">
                    <div v-else class="add-box">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </el-upload>
               <!-- upload -->
               *建议图片比例1440✖️600
            </el-form-item>
            <el-form-item >
                <el-button type="primary" @click="onSubmit" :disabled="disabled">
                    提交</el-button>
            </el-form-item>
        </el-form>
        <!-- 选择 -->
        <el-dialog append-to-body title="选择课节测试题" :visible.sync="showChooseTest">
            <CourseChoose @select="selectTest" @cancel="showChooseTest=false"/>
        </el-dialog>
         <!-- 选择课程 -->
        <el-dialog append-to-body title="选择课程" :visible.sync="showChooseClass">
            <ClassChoose @select="selectClass" @cancel="showChooseClass=false"/>
        </el-dialog>
    </div>
</template>
<script>
import CourseChoose from '@/components/CourseChoose.vue'
import ClassChoose from '@/components/ClassChoose.vue'
export default {
    components:{
        ClassChoose,
        CourseChoose
    },
    props:{
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            showChooseClass:false,
            showChooseTest:false,//显示测试题选择
            loading:false,
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            form:{},
            selectType:""
        }
    },
    created(){
      this.user = JSON.parse(sessionStorage.getItem('user'))
      this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods:{
        selectClass(id){
            this.form.url = "/pages/class/class?id="+id
            this.showChooseClass = false
        },
        selectTest(id){
            this.form.url = "/pages/test/test?id="+id
            this.showChooseTest = false
        },
        selectToType(index){
            console.log(index)
            switch(index*1){
                case 1:
                    this.showChooseTest = true
                    break;
                case 2:
                    this.showChooseClass = true
                    break;
            }
        },
        change(){
            this.loading=true
        },
        uploadFail(err){
            this.loading = false
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
            });
        },
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
            //suc
            this.form.image = this.API.apiUrl+response.data 
            //console.log(this.form)
            }else{
            this.$message({
                message:response.msg,
                type: 'warning'
            });
            }
        },
        onSubmit(){
            //save
            let form=this.form
            if(!form.image){
                this.$message({
                    message: "请添加banner图片",
                    type: 'warning'
                });
                return
            }
            this.loading = true 
            let params = this.form 
            params.method = "addClassBanner"
            this.$ajax.post(this.API.api,params).then(()=>{
                        //todo
                        this.loading = false
                        this.$emit("addSuc")
                        //this.$refs['form'].resetFields()
                        this.form={}
                        this.$message({
                            message: "上传成功！",
                            type: 'success'
                        });
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                        });
                    })
        }
    }
}
</script>
<style lang="less" scoped>
.add-banner{
    padding:10px 15px;
    .add-box{
        border:1px #dbdbdb solid;
    }
    .inline{
        display: flex;
    }
}
    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 258px;
    height: 178px;
    display: block;
  }
</style>