<template>
    <div class="other-box">
        <div class="flex-item swiper-box">
            <div class="m-title">
                <van-cell icon="setting-o" title="Banner列表" border/>
            </div>
            <van-swipe :autoplay="5000" >
                <van-swipe-item v-for="(image, index) in images" :key="index">
                    <div class="eidt-btn" @click="del(index)">
                        <el-tag size="mini" type="danger " v-if="hasRule">
                            <i class="el-icon-delete"></i>删除
                        </el-tag>
                    </div>
                    <img v-lazy="image" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="flex-item">
            <div class="m-title">
                <van-cell icon="add-o" title="添加banner" />
            </div>
            <AddBanner @addSuc="getBanner" :disabled="!hasRule"/>
        </div>
    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import AddBanner from '@/components/AddBanner'
import Vue from 'vue';
import { Lazyload,Swipe, SwipeItem ,Cell,Icon} from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Cell);
Vue.use(Icon);
export default {
    components:{
        AddBanner
    },
    data(){
        return{
            list:[],
            images: [
                'https://img01.yzcdn.cn/vant/apple-1.jpg',
                'https://img01.yzcdn.cn/vant/apple-2.jpg',
            ],
            showAdd:false,
            hasRule:checkRules(23)
        }
    },
    created(){
        this.getBanner()
    },
    methods:{
        addBanner(){
            this.showAdd = true
        },
        del(index){
            this.$confirm('确定删除这张banner吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        method:"delClassBanner",
                        id:this.list[index].id
                    }
                     this.$ajax.post(this.API.api,params).then(()=>{
                        //todo
                        this.loading = false
                        this.$message({
                            message: "删除成功！",
                            type: 'success'
                        });
                        this.getBanner()
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                        });
                    })
                }).catch(() => {     
                 });
            
        },
        //获取banner
        getBanner(){
            this.showAdd=false
            let params = {
                method:"getClassBanner"
            }
            this.$ajax.post(this.API.api,params).then(({data})=>{
                        //todo
                        this.loading = false
                        if(data){
                            let images=[]
                            this.list = data
                            data.forEach(item=>{
                                images.push(item.image)
                            })
                            this.images = images
                        }
                    }).catch(err=>{
                        //err
                        this.loading = false
                        this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                        });
                    })
        },
    }
}
</script>
<style lang="less" scoped>
.el-container{
    .el-container{
        .el-main{
            background: rgb(46, 69, 94);
        }
    }
}

.other-box{
    display: flex;
    flex-wrap: wrap;
    .van-cell{
        border-bottom: 1px #dbdbdb solid;
    }
    .swiper-box{
        width:50%;
        height: 150px;
        .add-b{
            cursor: pointer;
        }
        .add-b:hover{
            color: lightcoral;
        }
        .a-banner{
            margin-left: 10px;
            line-height: 15px;
        }
        .van-swipe-item{
            height: 200px;
            img{
                width:100%;
                max-height: 200px;
            }
            .edit-btn{
                position:absolute;
                top:10px;
                z-index: 10000000;
                right:10px;
            }
        }
    }
    .flex-item{
        flex:1;
    }
}
</style>