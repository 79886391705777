<template>
    <div class="CourseChoose-box" v-loading="loading">
         <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            @change="getTestList"
            v-model="keys">
        </el-input>
        <van-cell clickable @click="select(item.id)"
         :title="item.title" v-for="(item,index) in list" :key="index" :value="item.date|filterDate"/>
         <el-pagination
                v-model="page"
                hide-on-single-page
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
        </el-pagination>
        <div class="btn-box">
            <el-button type="danger" @click="cancel" plain>取消选定</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading:false,
            page:1,
            totalPage:1,
            pageSize:10,
            keys:"",
            list:[]
        }
    },
    filters:{
        filterDate(day){
            return day.split(" ")[0]
        }
    },
    created(){
        this.getTestList()
    },
    methods:{
        cancel(){
            this.$emit("cancel")
        },
        currentChange(e){
            console.log(e)
            this.page = e
            this.getTestList()
        },
        select(id){
            this.$emit("select",id)
        },
        //获取测试题列表
        getTestList(){
            let parmas ={
                method:'getCourse',
                keys:this.keys,
                page:this.page,
                pageSize:this.pageSize
            }
            this.loading = true
            this.$ajax.post(this.API.api,parmas).then(({data})=>{
                //todo
                this.loading = false  
                console.log(data)
                if(data.course){
                    this.totalPage=1
                    this.list = data.course
                }
            }).catch(err=>{
                //err
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>